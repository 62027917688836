@import "/assets/styles/utils.scss";

.steps-module {
    $steps-module: &;

    &:global(.has-background-color) {
        background-color: var(--accent);
        :global(.segment-business) & {
            background-color: black;

        }
    }
    color: white;

    &__title {
        text-align: center;
        margin-bottom: 1.5em;
        @media (max-width: 767px) {
            text-align: left;
        }
    }

    &--direction-vertical {
        #{$steps-module}__steps {
            flex-direction: column;
        }
    }
}

.steps {
    display: flex;
    gap: 16px;
    max-width: 900px;
    margin: 40px auto;

    @media (max-width: 767px) {
        flex-direction: column;
    }
    @media (max-width: 767px) {
        margin-right: 0;
        margin-left: 0;
    }
}

.item {
    display: flex;
    position: relative;
    flex: 1;
    z-index: 2;
    background-color: white;
    color: black;
    border-radius: 8px;
    padding: 20px;
    flex-direction: row;

    @media (min-width: 768px) {
        text-align: left;
        flex-direction: column;
    }

    &--wide-number {
        flex-direction: column;
    }

    &__number {
        font-size: em(56);
        font-weight: 300;
        min-width: 20%;
        line-height: 100%;
        margin-bottom: 16px;

        @media (min-width: 768px) {
            text-align: left;
        }
    }

    h3 {
        margin-top: 0;
        margin-bottom: 8px;
        @media (max-width: 767px) {
            padding-top: 0;
        }
    }

    &__cta {
        margin-top: 1.25em;
        &--arrow {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
